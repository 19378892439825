import { render, staticRenderFns } from "./MediaGallery.vue?vue&type=template&id=5131f76c&scoped=true&"
import script from "./MediaGallery.vue?vue&type=script&lang=js&"
export * from "./MediaGallery.vue?vue&type=script&lang=js&"
import style0 from "./MediaGallery.vue?vue&type=style&index=0&id=5131f76c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5131f76c",
  null
  
)

export default component.exports