<template>
  <div>
    <button
      aria-label="Share the document"
      class="btn btn-large btn-share"
      @click="share"
    >
      <i class="UI-icon UI-share" />
      {{ $t('document.share') }}
    </button>
    <transition name="fade">
      <div
        v-if="shareOpen"
        :class="isMobile ? 'mobile' : 'desktop'"
        class="web-share"
        @click="shareOpen = false"
      >
        <div class="web-share-container web-share-grid">
          <a
            v-if="whatsapp"
            :href="whatsapp"
            class="web-share-item web-share-whatsapp web-share-slide-in"
            target="_blank"
          >
            <div class="web-share-icon-whatsapp" />
            <div class="web-share-item-desc">Whatsapp</div>
          </a>
          <a
            v-if="messenger"
            :href="messenger"
            class="web-share-item web-share-facebook web-share-slide-in"
          >
            <div class="web-share-icon-facebook" />
            <div class="web-share-item-desc">Facebook</div>
          </a>
          <a
            v-if="email"
            :href="email"
            class="web-share-item web-share-email web-share-slide-in"
          >
            <div class="web-share-icon-email" />
            <div class="web-share-item-desc">Email</div>
          </a>
          <a
            v-if="sms"
            :href="sms"
            class="web-share-item web-share-sms web-share-slide-in"
          >
            <div class="web-share-icon-sms" />
            <div class="web-share-item-desc">SMS</div>
          </a>
          <a
            href="#"
            class="web-share-item web-share-copy web-share-slide-in"
            @click.prevent="copy"
          >
            <div class="web-share-icon-copy" />
            <div class="web-share-item-desc">Copy</div>
          </a>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { writeText } from 'clipboard-polyfill'
const fbAppId = '821076351588265'
const ua = navigator.userAgent.toLowerCase()

export default {
  name: 'WebShare',
  props: {
    title: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      isMobile: navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone|iPad|iPod/i),
      url: window.location.href,
      redirectUri: window.location.href,
      shareOpen: false
    }
  },
  computed: {
    formattedText () {
      return `${this.title}

${this.text}

${this.$t('document.read-more')} ${this.url}`
    },
    whatsapp () {
      return this.isMobile ? `whatsapp://send?text=${this.title} ${this.url}` : `https://api.whatsapp.com/send?text=${this.encodeUrl(this.formattedText)}`
    },
    messenger () {
      return this.isMobile ? `fb-messenger://share/?link=${this.encodeUrl(this.url)}&app_id=${fbAppId}` : `http://www.facebook.com/dialog/send?app_id=${fbAppId}&link=${this.encodeUrl(this.url)}&redirect_uri=${this.encodeUrl(this.redirectUri)}`
    },
    email () {
      return `mailto:?subject=${this.title}&body=${this.encodeUrl(this.formattedText)}`
    },
    sms () {
      if (!this.isMobile) return null
      if (ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1) {
        return `sms:&body=${this.title} ${this.url}`
      } else {
        return `sms:?body=${this.title} ${this.url}`
      }
    }
  },
  methods: {
    encodeUrl (url) {
      return encodeURIComponent(url)
    },
    async share () {
      if (navigator.share) {
        try {
          await navigator.share({
            title: this.title,
            text: this.text,
            url: this.url
          })
        } catch (error) {
          this.$toasted.global.error(error)
        }
      } else {
        this.shareOpen = true
        this.$nextTick(this.addStaggerDelays)
      }
    },
    async copy () {
      try {
        await writeText(this.url)
        this.$toasted.show(this.$t('document.copied'), {
          position: 'bottom-center',
          duration: 1500,
          type: 'success'
        })
      } catch (error) {
        this.$toasted.global.error(error)
      }
    },
    addStaggerDelays () {
      const interval = 0.07
      const links = document.getElementsByClassName('web-share-slide-in')
      for (let i = 0; i < links.length; i++) {
        links[i].style.animationDelay = `${interval * (i + 1)}s`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .btn-share {
    display: inline-block;
    width: auto;
    padding: 10px 16px;
    font-size: 1rem;
    margin-bottom: 15px;
    i {
      font-weight: normal;
      font-size: 1rem;
    }
    @media print {
      display: none;
    }
  }
  .fade-enter-active, .fade-leave-active {
    .web-share-container {
      transition: transform .4s, opacity .4s;
    }
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
    .web-share-container {
      transform: translateY(10vh);
      opacity: 0;
    }
  }
  /* Fonts */
  .web-share {
    transition: opacity 0.3s ease;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", arial, sans-serif;
    font-weight: 400;
    font-size: 13px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }

  .web-share-title {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.12em;
    opacity: 0.7;
  }

  .web-share {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 100000;
    background: rgba(black, 0.4);
    display: flex;
  }

  .web-share-container {
    width: 100%;
    background: white;
    padding: 16px 23px;
    text-align: center;
    color: black;
    transform: translateY(0);
    opacity: 1;
  }

  .web-share-container.web-share-cancel {
    color: #0076FF;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
  }

  .web-share-item {
    margin: 16px 8px;
    display: inline-block;
    text-decoration: none;
    color: black;
    cursor: pointer;
    width: 64px;
  }

  .web-share-item [class^="web-share-icon-"] {
    border-radius: 12px;
    padding: 12px;
    color: black;
    font-size: 32px;
    width: 32px;
    height: 32px;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0 auto;
  }

  .web-share-item-desc {
    text-align: center;
    margin-top: 4px;
  }

  .web-share.desktop .web-share-sms {
    display: none;
  }

  .web-share-icon-whatsapp {
    background-image: url('data:image/svg+xml,<svg width="24" height="25" viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg"><path d="M20.504 3.504C18.246 1.246 15.246 0 12.05 0 5.466 0 .103 5.36.103 11.945c-.004 2.106.546 4.16 1.593 5.973L0 24.108l6.336-1.663c1.742.953 3.71 1.453 5.71 1.457h.005c6.587 0 11.946-5.36 11.95-11.95 0-3.19-1.242-6.19-3.496-8.448zm-8.453 18.38h-.003c-1.78 0-3.53-.482-5.055-1.384l-.363-.215-3.763.985 1.004-3.665-.233-.375c-.996-1.582-1.52-3.41-1.52-5.285 0-5.472 4.457-9.925 9.938-9.925 2.652 0 5.144 1.035 7.02 2.91 1.874 1.88 2.905 4.37 2.905 7.023 0 5.477-4.457 9.93-9.93 9.93zm5.446-7.44c-.297-.147-1.766-.87-2.04-.967-.272-.102-.472-.15-.67.148-.2.3-.77.973-.946 1.172-.172.195-.348.223-.645.074-.3-.147-1.26-.464-2.402-1.483-.887-.79-1.488-1.77-1.66-2.067-.176-.3-.02-.46.13-.61.135-.132.3-.347.448-.523.15-.17.2-.296.302-.496.097-.198.047-.374-.028-.522-.074-.148-.67-1.62-.92-2.22-.244-.58-.49-.5-.673-.51-.17-.008-.37-.008-.57-.008-.2 0-.523.074-.797.375-.273.297-1.043 1.02-1.043 2.488 0 1.47 1.07 2.89 1.22 3.09.148.195 2.105 3.21 5.1 4.504.712.308 1.266.492 1.7.63.715.225 1.367.194 1.883.12.574-.086 1.765-.723 2.015-1.422.247-.695.247-1.293.172-1.418-.074-.125-.273-.2-.574-.352z" fill="black" fill-rule="evenodd"/></svg>');
  }

  .web-share-icon-telegram {
    background-image: url('data:image/svg+xml,<svg width="26" height="23" viewBox="0 0 26 23" xmlns="http://www.w3.org/2000/svg"><path d="M24.075.002c-.336.016-.652.112-.933.22h-.004c-.285.11-1.64.673-3.7 1.523l-7.385 3.063C6.755 7.005 1.547 9.168 1.547 9.168l.063-.023s-.36.115-.735.37c-.19.122-.402.292-.586.557-.185.266-.333.674-.28 1.093.09.712.56 1.14.896 1.374.34.237.664.35.664.35h.008l4.884 1.62c.22.692 1.49 4.8 1.794 5.748.18.565.355.92.574 1.19.105.137.23.253.38.345.057.035.12.062.182.085.02.01.04.015.063.02l-.05-.012c.015.003.027.015.038.02.04.01.067.014.118.022.774.23 1.395-.243 1.395-.243l.036-.027 2.884-2.585 4.833 3.65.11.048c1.008.435 2.027.193 2.566-.234.544-.43.755-.98.755-.98l.035-.09 3.735-18.843c.105-.466.133-.9.016-1.324-.118-.424-.42-.82-.782-1.032-.367-.216-.73-.28-1.067-.266zm-.1 2.02c-.005.062.007.054-.02.173v.012l-3.7 18.647c-.016.027-.044.085-.118.143-.078.06-.14.1-.465-.027l-5.91-4.464-3.572 3.205.75-4.716 9.658-8.866c.4-.365.266-.442.266-.442.027-.447-.602-.13-.602-.13l-12.178 7.43-.004-.02-5.838-1.936v-.003c-.003 0-.01-.004-.015-.004.004 0 .03-.012.03-.012l.032-.014.03-.012 10.51-4.36c2.654-1.1 5.326-2.208 7.38-3.062 2.056-.85 3.576-1.474 3.662-1.51.082-.03.043-.03.102-.03z" fill-rule="nonzero" fill="black"/></svg>');
  }

  .web-share-icon-facebook {
    background-image: url('data:image/svg+xml,<svg width="24" height="25" viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg"><path d="M12 0C5.41 0 0 5.137 0 11.5c0 3.39 1.57 6.402 4 8.5v4.625l4.438-2.22c1.128.34 2.308.595 3.562.595 6.59 0 12-5.137 12-11.5S18.59 0 12 0zm0 2c5.56 0 10 4.266 10 9.5S17.56 21 12 21c-1.195 0-2.336-.227-3.406-.594l-.406-.125L6 21.376v-2.25l-.375-.313C3.405 17.063 2 14.442 2 11.5 2 6.266 6.44 2 12 2zm-1.125 6.344l-6.03 6.375 5.405-3 2.875 3.092 5.97-6.468-5.282 2.97-2.938-2.97z" fill-rule="nonzero" fill="black"/></svg>')
  }

  .web-share-icon-email {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="black" height="32" viewBox="0 0 24 24" width="32"><path d="M0 0h24v24H0z" fill="none"/><path d="M 20,4 4,4 C 2.9,4 2.01,4.9 2.01,6 L 2,18 c 0,1.1 0.9,2 2,2 l 16,0 c 1.1,0 2,-0.9 2,-2 L 22,6 C 22,4.9 21.1,4 20,4 z m 0.508475,14.508475 -17.0169495,0 0,-10.7118648 L 12,12.79661 20.508475,7.7966102 z M 11.694915,11 3.4915255,5.4915255 l 17.0169495,0 z"/></svg>');
  }

  .web-share-icon-sms {
    background-image: url('data:image/svg+xml,<svg fill="black" height="36" viewBox="0 0 24 24" width="36" xmlns="http://www.w3.org/2000/svg"><path d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM9 11H7V9h2v2zm4 0h-2V9h2v2zm4 0h-2V9h2v2z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
  }

  .web-share-icon-copy {
    background-image: url('data:image/svg+xml,<svg width="24" height="28" viewBox="0 0 24 28" xmlns="http://www.w3.org/2000/svg"><path d="M13.867 0c-.56 0-1.178.006-1.864.006H7.64c-1.633 0-2.873-.003-3.9.103-1.025.106-1.926.336-2.592.946-.665.61-.916 1.437-1.03 2.377C0 4.373.007 5.51.007 7.005v10.019c0 2.547-.12 4.066.642 5.337.38.636 1.065 1.1 1.817 1.324.58.173 1.24.238 1.977.278.014.2.015.432.038.615.116.94.367 1.766 1.033 2.376.666.61 1.567.84 2.592.945 1.026.106 2.265.102 3.896.102h4.363c1.633 0 2.874.003 3.9-.104 1.026-.106 1.927-.336 2.592-.947.666-.61.916-1.437 1.03-2.377.116-.94.112-2.076.112-3.572v-9.996c0-1.498.003-2.635-.113-3.576-.116-.94-.367-1.766-1.033-2.376-.666-.61-1.567-.84-2.592-.945-.206-.022-.466-.022-.69-.036-.046-.706-.122-1.332-.33-1.885-.256-.675-.78-1.282-1.47-1.615-1.036-.5-2.22-.567-3.905-.57zM7.64 2.006h4.363c2.74 0 4.282.107 4.752.333.236.113.3.173.424.5.09.236.15.66.197 1.17-.34 0-.632-.005-1.01-.005h-4.364c-1.633 0-2.874-.003-3.9.104-1.026.105-1.927.335-2.592.946-.665.61-.915 1.436-1.03 2.376-.115.94-.11 2.076-.11 3.572v9.998c0 .356.004.63.005.95-.53-.04-.976-.093-1.235-.17-.38-.112-.452-.178-.577-.386-.25-.417-.375-1.827-.375-4.372v-.01V7.005c0-1.495.007-2.604.098-3.35.09-.745.25-1.045.405-1.186.155-.143.482-.29 1.296-.374.813-.085 2.022-.09 3.655-.09zm4.363 4h4.363c1.63 0 2.84.005 3.653.09.812.082 1.14.228 1.294.37.154.14.315.44.407 1.187.092.745.1 1.854.1 3.35v9.998c0 1.496-.008 2.605-.1 3.35-.09.746-.25 1.046-.404 1.188-.154.14-.482.288-1.295.373-.812.085-2.022.09-3.654.09h-4.363c-1.63 0-2.84-.006-3.653-.09-.813-.083-1.14-.23-1.295-.37-.154-.142-.315-.442-.407-1.188-.092-.745-.098-1.854-.098-3.35v-9.998c0-1.495.007-2.604.098-3.35.092-.745.25-1.045.405-1.187.154-.14.482-.288 1.295-.372.813-.085 2.023-.09 3.655-.09z" fill="black" fill-rule="evenodd"/></svg>');
  }

  .web-share-slide-in {
    animation-name: slide;
    animation-duration: 0.3s;
    animation-fill-mode: backwards;
  }

  @keyframes slide {
    from {
      transform: translateY(100%);
      opacity: 0;
    }
    to {
      transform: translateY(0%);
      opacity: 1;
    }
  }
</style>
